@use '../../styles/scss/wonderschool/variables';

.container {
  margin-bottom: 2rem;
}

.table {
  width: 100%;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.tableHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0 16px;
  border-bottom: 1px solid #e8e8e8;

  h3 {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
  }
}

.datePickerContainer {
  display: flex;
  align-items: center;

  :global(.ui.input) {
    width: auto; // Override Semantic UI's default width
    
    input {
      width: 150px; // Specific width for the date input
    }
  }
}

.tableContent {
  padding: 0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
}

.dateRange {
  color: variables.$gray;
  font-size: 14px;
}

.row {
  display: flex;
  border-bottom: 1px solid #e8e8e8;

  &:last-child {
    border-bottom: none;
  }

  &.headerRow {
    background-color: #f8f9fa;
    color: #4a4a4a;
    font-weight: 500;
    border-bottom: 1px solid #e8e8e8;

    .labelCell {
      background-color: #f8f9fa;
    }

    .cell {
      white-space: nowrap;
    }
  }

  &.totalRow {
    background-color: #fafafa;
    font-weight: 600;
  }
}

.cell {
  flex: 1;
  padding: 16px 12px;
  text-align: center;
  font-size: 14px;
  color: #333;
  border-right: 1px solid #e8e8e8;

  &:last-child {
    border-right: none;
  }

  &.labelCell {
    flex: 1.5;
    text-align: left;
    font-weight: 500;
    padding-left: 24px;
    background-color: white;
    border-right: 1px solid #e8e8e8;
  }

  // Header cells should always be centered
  .headerRow & {
    display: flex;
    align-items: center;
    justify-content: center;

    .fullDayName, .shortDayName {
      width: 100%;
      text-align: center;
    }
  }

  // Medium screens
  @media (max-width: 992px) {
    padding: 16px 8px;
  }

  // Mobile styles
  @media (max-width: 768px) {
    min-width: 45px;
    padding: 12px 8px;
    font-size: 14px;

    &.labelCell {
      min-width: 90px;
      
      .shortText {
        white-space: normal;
        word-break: break-word;
        hyphens: auto;
      }
    }
  }
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  padding: 2rem;
}

.fullDayName {
  display: block;
  
  @media (max-width: 992px) {
    display: none;
  }

  &.scheduleNotRecorded {
    display: flex;
    gap: 4px;
    justify-content: center;
    align-items: center;
    
    // Progressive font size reduction only for Schedule Not Recorded
    @media (max-width: 1400px) {
      font-size: 13px;
    }
    
    @media (max-width: 1200px) {
      font-size: 12px;
    }
    
    @media (max-width: 1100px) {
      font-size: 11px;
    }
    
    @media (min-width: 993px) {
      flex-direction: row;
      
      span:first-child::after {
        content: ' ';
      }
    }

    // Hide completely in medium/mobile
    @media (max-width: 992px) {
      display: none;
    }
  }
}

.shortDayName {
  display: none;
  text-align: center;
  width: 100%;
  
  @media (max-width: 992px) {
    display: block;
  }
}

.fullText {
  display: block;
  
  @media (max-width: 992px) {
    display: none;
  }
}

.shortText {
  display: none;
  
  @media (max-width: 992px) {
    display: block;
    line-height: 1.2;
  }
} 
