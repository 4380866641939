@use '../../../styles/scss/wonderschool/variables';

.container {
  margin-top: 20px;
  padding: 10px 0;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.title {
  font-size: 20px;
  font-weight: 500;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.monthsGrid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    gap: 16px;
    
    .monthColumn {
      flex: 0 0 calc(33.33% - 11px); // Account for gap
    }
  }
}

.box {
  margin: 1em 0;
  border-radius: .28571429rem;
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
  padding: 1em; /* space inside the box */
  flex-shrink: 0;
}

.monthColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.monthLabel {
  font-size: 14px;
  color: variables.$gray;
  margin-bottom: 4px;
}

.amount {
  font-size: 16px;
  font-weight: 700;
  color: variables.$green;
} 
